ort 8082
<template>
  <div class="container-fluid">
      <div class="card">
        <div class="card-body p-0">
          <b-table striped hover selectable responsive :items="tableData" :fields="columns" :current-page="currentPage" :busy="loading" :empty-text="'No data available.'" show-empty>
            <template #empty="scope">
              <h4 class="text-center">{{ scope.emptyText }}</h4>
            </template>

            <template #table-busy>
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="spinner-border" role="status"></div>
                <p class="text-center mt-4"><strong>Loading...</strong></p>
              </div>
            </template>

            <template #cell(id)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(name)="data">
              <div class="d-flex align-items-center">
                <image-zoom style-class="avatar-sm mr-1">
                  <template v-slot:image>
                    <img :src="data.item.image || defaultBus" alt="Vehicle Category" class="avatar avatar-sm avatar-img rounded-circle" />
                  </template>
                </image-zoom>
                <span>
                  {{ data.item.name }}
                </span>
              </div>rigin test
            </template>

            <template #cell(description)="data">
              {{ data.item.description || 'N/A' }}
            </template>

            <template #cell(action)="data" v-if="userHasPermission('adminConfig003')">
              <button class="btn btn-outline-primary btn-sm mr-2" @click="openSetupModal(data.item)">
                Edit
              </button>

              <button class="btn btn-outline-danger btn-sm" @click="openDeleteModal(data.item)">
                Delete
              </button>
            </template>
            <template #cell(action)="data" v-else>
              <button class="btn btn-outline-primary btn-sm mr-2">
                UnAuthorised
              </button>
            </template>
          </b-table>
        </div>
        <div class="card-footer" v-if="totalRecords">
          <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
        </div>
      </div>

          <delete-prompt-modal modal-title="Are you sure you want to delete ?" @close="showDeleteModal = false" :check-process-label="'Checking vehicles attached to category...'" :verifying-delete="verifyingDelete" :verification-message="verificationMessage" :processing-action="deletingCategory"
        :show="showDeleteModal" prompt-text="If you want to delete this category, type YES to proceed" :delete-function="deleteVehicleCategory">
      </delete-prompt-modal>
        <vehicle-category-setup @close="showSetupModal = false" @config-saved="refreshView" :model="selectedCategory" :show="showSetupModal">
      </vehicle-category-setup>
    </div>
</template>

<script setup>
import DeletePromptModal from '@/components/modals/DeletePromptModal'
import ImageZoom from '@/components/core/ImageZoom'
import { userHasPermission } from '@/composables/core/permissions'
import VehicleCategorySetup from '@/views/AdminConfig/CategoryManagement/VehicleCategorySetupModal'
import { extractErrorMessage } from '@/utils/helpers'

import defaultBusImage from '@/assets/img/default-bus.png'
import { ref, watch, onMounted, reactive, toRefs } from 'vue'

const  columns = [
        {key: 'id', label: 'ID'},
        {key: 'name', label: 'Category'},
        {key: 'description', label: 'Description'},
        { key: 'action', label: 'Action'}
]

const currentPage = ref(1)

const state = reactive({

  perPage: 10,
  loading: false,
  showSetupModal: false,
  showDeleteModal: false,
  deletingCategory: false,
  verifyingDelete: false,
  verificationMessage: '',
  selectedCategory: null,
  totalRecords: 0,
  tableData: [],
  defaultBus: defaultBusImage,
})




watch(currentPage, (value, oldValue) => {
  if (value && value !== oldValue) {
    loadCategories()
  }
})

// Call init on component mount
onMounted(() => {
  init()
})

// Methods
function init() {
  loadCategories()
}

function refreshView() {
  if (state.currentPage !== 1) {
    state.currentPage = 1
  } else {
    loadCategories()
  }
}

function loadCategories() {
  state.loading = true
  const params = { metadata: true, limit: state.perPage, page: state.currentPage }
  const costOfSupply = new Map()

  axios
    .get('/v1/vehicle-types/cost-of-supply?limit=0')
    .then((response) => {
      if (response.data?.data?.length) {
        response.data.data.forEach((costConfig) => {
          const key = costConfig.vehicle_type_id
          // Destructured as the API sends un-required additional payload
          const { id, city_id, cost_of_supply, vehicle_type_id, currency, description } = costConfig
          if (costOfSupply.has(key)) {
            const prevData = costOfSupply.get(key)
            prevData.push({ id, city_id, cost_of_supply, vehicle_type_id, currency, description })
            costOfSupply.set(key, prevData)
          } else {
            costOfSupply.set(key, [{ id, city_id, cost_of_supply, vehicle_type_id, currency, description }])
          }
        })
      }
      return axios.get('/v1/vehicle-types', { params })
    })
    .then((res) => {
      state.tableData = (res.data?.data || []).map((obj) => {
        return {
          ...obj,
          cityPricing: costOfSupply.get(obj.id) || [],
        }
      })
      state.totalRecords = res?.data?.metadata?.total
    })
    .finally(() => {
      state.loading = false
    })
}

function openSetupModal(data = null) {
  state.selectedCategory = data
  state.showSetupModal = true
}

function openDeleteModal(data) {
  state.selectedCategory = data
  state.showDeleteModal = true
  verifyCategoryData(data.name)
}

function verifyCategoryData(id) {
  state.verifyingDelete = true
  state.verificationMessage = ''
  axios
    .get(`/v1/vehicles?metadata=true&limit=0&page=1&type=${id}`)
    .then((res) => {
      const count = res.data.metadata.total
      if (count) {
        state.verificationMessage = `Please note that ${count} vehicles are attached to this category you're about to delete`
      } else {
        state.verificationMessage = 'No vehicles are attached to this category you\'re about to delete'
      }
    })
    .catch(() => {
      state.verificationMessage = 'Unable to determine vehicles attached to category'
    })
    .finally(() => {
      state.verifyingDelete = false
    })
}

function deleteVehicleCategory() {
  state.deletingCategory = true
  axios
    .delete(`/v1/vehicle-types/${state.selectedCategory.id}`)
    .then(() => {
      refreshView()
    })
    .catch((error) => {
      const msg = extractErrorMessage(error, 'An error occurred please try again later.')
      swal('Could not delete route', msg, 'error')
    })
    .finally(() => {
      state.deletingCategory = false
      state.showDeleteModal = false
    })
}

// Expose reactive properties as refs for template access
const {
  perPage,
  loading,
  showSetupModal,
  showDeleteModal,
  deletingCategory,
  verifyingDelete,
  verificationMessage,
  selectedCategory,
  totalRecords,
  tableData,
  defaultBus,
} = toRefs(state)
</script>

<style scoped>

</style>
